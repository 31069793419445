import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDModal from 'components/MDModal'
import MDTypography from 'components/MDTypography'
import { useAppServices, useUploadImage } from 'hook/services'
import React, { useState } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { TextareaAutosize } from '@mui/material'
import { useLocation } from 'react-router-dom'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 400,
  bgcolor: 'background.paper',
  border: '2px  #000',
  boxShadow: 24,
  p: 4,
}
const DocsValidate = yup.object({
  title: yup.string().required().label('Title'),
})
function PromptCreate({ open, onClose, SubCategory, update_data }) {
  console.log(SubCategory);
  
  const { state } = useLocation();
  const uploadImage = useUploadImage()
  // console.log(category)
  const [processing, setProcessing] = useState(false)
  const AppService = useAppServices()
  const handleSubmit = async (e) => {
    console.log(state);
    setProcessing(true)
    e.preventDefault()
    // const image_url = await uploadImage({
    //   file: e.target.image.files[0],
    //   desiredPath: `design/logo/image`,
    // })
    //   .then((res) => {
    //     return res.response.data
    //   })
    //   .catch((err) => {
    //     return null
    //   })
    // const payload = {
    //   name: e.target.name.value,
    //   description: e.target.description.value,
    //   // image: image_url,
    //   type: 'super-admin',
    // }
    const payload = {
      title: e.target.title.value,
      prompt_value: e.target.value.value,
      category: SubCategory.category_id,
      sub_category: SubCategory._id,
    }
     
    // if (category) {
    //   payload._id = category._id
    //   payload.type = category?._type
    // }

    const { response } = await AppService.docs[update_data ? 'updateDocs' : 'createDocs']({
      payload,
      toaster: true,
    })
    setProcessing(false)
    if (response) {
      onClose()
    }
  }
  const initstate = {
    title: '',
  }

  const formik = useFormik({
    initialValues: { ...initstate },
    onSubmit: handleSubmit,
    validationSchema: DocsValidate,
  })

  return (
    <MDBox>
      <MDModal
        open={open}
        onClose={onClose}
        height={400}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox role="form" component="form" onSubmit={handleSubmit} sx={style}>
          <MDTypography variant="h6">Create Prompt</MDTypography>
          <MDBox my={2}>
            <MDInput
              type="text"
              label="Title"
              name="title"
              onChange={formik.handleChange}
              inputProps={{ onFocus: formik.handleBlur }}
              value={formik.values.title}
              error={formik.touched.title && formik.errors.title}
              helperText={formik.touched.title && formik.errors.title ? formik.errors.title : ''}
              success={formik.touched.title && !formik.errors.title}
              fullWidth
            />
          </MDBox>
          <MDBox my={2}>
            <TextareaAutosize
              className="form-control"
              style={{ minHeight: '70px' }}
              placeholder="Value"
              name="value"
              onChange={formik.handleChange}
              inputProps={{ onFocus: formik.handleBlur }}
              value={formik.values.value}
              error={formik.touched.value && formik.errors.value}
              helperText={formik.touched.value && formik.errors.value ? formik.errors.value : ''}
              success={formik.touched.value && !formik.errors.value}
              fullWidth
            />
          </MDBox>
          <MDButton
            variant="gradient"
            color="info"
            type="submit"
            className="brand-card-background"
            loading={processing}
            disabled={processing || !formik.isValid}
            sx={{ mt: 4, mb: 1 }}
            fullWidth
          >
            {'Add'}
          </MDButton>
        </MDBox>
      </MDModal>
    </MDBox>
  )
}

export default PromptCreate
